// Nav Links Icons
import CasesIcon from 'oldAssets/header/Cases.svg';
import UpgradeIcon from 'oldAssets/header/Upgrade.svg';
import BattleIcon from 'oldAssets/header/Battle.svg';
import UserGiveawayIcon from 'assets/header/UserGiveaway.svg';
import EventIcon from 'assets/event/header-link/event.svg';

// Site & Support Links Icons
import MarketIcon from 'oldAssets/header/Market.svg';
import SkinExchanger from 'oldAssets/header/SkinExchanger.svg';
// import FreeIcon from 'oldAssets/new/IconFree.svg';
import GiftIcon from 'assets/icon24/Gift.svg';
// import PartnershipIcon from 'oldAssets/new/IconPartnership.svg';
import PromoIcon from 'oldAssets/new/HeaderPromo.svg';
import SupportIcon from 'oldAssets/new/IconSupport.svg';

// Social Links Icons
import InstagramIcon from 'oldAssets/new/IconInstagram.svg';
import DiscordIcon from 'oldAssets/new/IconDiscord.svg';
import XTwiterIcon from 'oldAssets/new/IconXTwitter.svg';
import FacebookIcon from 'oldAssets/new/IconFacebook.svg';
// import SteamIcon from 'oldAssets/new/IconSteam.svg';

export const NAV_LINKS = [
  {
    icon: <CasesIcon />,
    text: 'Cases',
    link: '/',
    parentLink: '/'
  },
  {
    icon: <UpgradeIcon />,
    text: 'Upgrader',
    link: '/upgrader',
    parentLink: '/upgrader'
  },
  {
    icon: <BattleIcon />,
    text: 'Case Battle',
    link: '/battle/list',
    parentLink: '/battle'
  }
];

export const USER_GIVEAWAY_LINK = {
  icon: <UserGiveawayIcon />,
  text: 'User giveaway',
  link: '/giveaways/list/all',
  parentLink: '/giveaways'
  // isNew: true
};

export const EVENT_LINK = {
  icon: <EventIcon />,
  text: 'Event',
  link: '/event',
  parentLink: '/event'
  // isNew: true
};

export const SITE_LINKS = {
  // market: {
  //   icon: <MarketIcon />,
  //   text: 'Market',
  //   color: '#D95751'
  // },
  skinExchanger: {
    icon: <SkinExchanger />,
    text: 'Skin Changer',
    link: '/exchanger',
    color: '#6498E1'
  },
  freecase: {
    icon: <GiftIcon />,
    text: 'Free case',
    link: '/free-case',
    color: '#FFB872'
  },
  promocode: {
    icon: <PromoIcon />,
    text: 'Promocode',
    color: '#36BB9D'
  },
  support: {
    icon: <SupportIcon />,
    text: 'Support',
    link: '/support',
    color: '#88898F'
  },
  wiki: {
    icon: <MarketIcon />,
    text: 'Items wiki',
    link: '/csgo-skin',
    color: '#FF5254'
  }
};

export const SOCIAL_LINKS = [
  {
    icon: <InstagramIcon />,
    link: 'https://instagram.com/dropskinofficial',
    color: '#C23DDE'
  },
  {
    icon: <FacebookIcon />,
    link: 'https://www.facebook.com/dropskincsgo',
    color: '#5168F6'
  },
  {
    icon: <DiscordIcon />,
    link: 'https://discord.gg/drop-skin-835576838826098748',
    color: '#7F4AF6'
  },
  {
    icon: <XTwiterIcon />,
    link: 'https://x.com/drop_skin',
    color: '#fff'
  }
  // {
  //   icon: <SteamIcon />,
  //   link: 'https://steamcommunity.com/groups/dropskinofficial',
  //   color: '#5168F6'
  // }
];
