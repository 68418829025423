import React from 'react';
import Link from 'next/link';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';

// import { useAppSelector } from 'store';
import { usePromocodeModalContext } from 'context/modals/PromocodeModalProvider/PromocodeModalProvider';
// import { useLoginModalContext } from 'context/modals/LoginModalProvider/LoginModalProvider';
// import { useMarketModalContext } from 'context/modals/MarketModalProvider/MarketModalProvider';

import { SITE_LINKS } from 'constants/nav-links';

// import SpecialGiveawayIcon from 'assets/header/Star.svg';
import FaceitHubIcon from 'assets/header/Faceit.svg';

// const SPECIAL = {
//   type: 'link',
//   icon: <SpecialGiveawayIcon />,
//   text: 'Special Giveaway',
//   link: 'https://gleam.io/C5zUd/dropskin-giveaway-back-to-school',
//   color: '#40B3F3',
//   target: '_blank'
// };
const FACEIT_HUB = {
  type: 'link',
  icon: <FaceitHubIcon />,
  text: 'Faceit Club',
  link: 'https://www.faceit.com/en/inv/Wz08BaY',
  color: '#FF5500',
  target: '_blank'
};

const SiteLinks: React.FC<BoxProps & { handleClick?: () => void; isDrawer: boolean }> = ({
  handleClick = undefined,
  isDrawer = false,
  ...props
}) => {
  return (
    <Box display='flex' gap={0.5} flexDirection='row' justifyContent='flex-start' {...props}>
      <SiteButton
        icon={FACEIT_HUB?.icon}
        color={FACEIT_HUB?.color}
        text={FACEIT_HUB?.text}
        href={FACEIT_HUB?.link}
        target={'_blank'}
        rel='noopener noreferrer'
        handleClick={handleClick}
      />

      {/* SPECIAL */}
      {/*<SiteButton*/}
      {/*  icon={SPECIAL.icon}*/}
      {/*  color={SPECIAL.color}*/}
      {/*  text={SPECIAL.text}*/}
      {/*  href={SPECIAL.link}*/}
      {/*  target={'_blank'}*/}
      {/*  handleClick={handleClick}*/}
      {/*/>*/}

      {/* MARKET */}
      {/* <MarketModalButton handleClick={handleClick} /> */}

      {/* SKIN CHANGER */}
      <SiteButton
        icon={SITE_LINKS?.skinExchanger?.icon}
        color={SITE_LINKS?.skinExchanger?.color}
        text={SITE_LINKS?.skinExchanger?.text}
        link={SITE_LINKS?.skinExchanger?.link}
        handleClick={handleClick}
      />

      {/* FREE CASE */}
      <SiteButton
        icon={SITE_LINKS?.freecase?.icon}
        color={SITE_LINKS?.freecase?.color}
        text={SITE_LINKS?.freecase?.text}
        link={SITE_LINKS?.freecase?.link}
        handleClick={handleClick}
      />

      {/* PROMOCODE */}
      <PromocodeModalButton handleClick={handleClick} />

      {/* Wiki */}
      <SiteButton
        icon={SITE_LINKS?.wiki?.icon}
        color={SITE_LINKS?.wiki?.color}
        text={SITE_LINKS?.wiki?.text}
        link={SITE_LINKS?.wiki?.link}
        handleClick={handleClick}
      />

      {/* SUPPORT */}
      {isDrawer && (
        <SiteButton
          icon={SITE_LINKS?.support?.icon}
          color={SITE_LINKS?.support?.color}
          text={isDrawer && SITE_LINKS?.support?.text}
          link={SITE_LINKS?.support?.link}
          handleClick={handleClick}
          // sx={{ span: { m: 0 } }}
        />
      )}
    </Box>
  );
};

export default SiteLinks;

// -------------------------

// const MarketModalButton = ({ handleClick = () => {} }) => {
//   const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
//   const { showLoginModal } = useLoginModalContext();
//   const { showMarketModal } = useMarketModalContext();

//   const handleButtonClick = () => {
//     if (isAuthenticated) {
//       showMarketModal();
//     } else {
//       showLoginModal();
//     }
//     handleClick();
//   };

//   return (
//     <SiteButton
//       icon={SITE_LINKS?.market?.icon}
//       text={SITE_LINKS?.market?.text}
//       color={SITE_LINKS?.market?.color}
//       handleClick={handleButtonClick}
//     />
//   );
// };

// -------------------------

const PromocodeModalButton = ({ handleClick = () => {} }) => {
  const { handleModal } = usePromocodeModalContext();
  const handleButtonClick = () => {
    handleModal();
    handleClick();
  };

  return (
    <SiteButton
      icon={SITE_LINKS?.promocode?.icon}
      text={SITE_LINKS?.promocode?.text}
      color={SITE_LINKS?.promocode?.color}
      handleClick={handleButtonClick}
    />
  );
};

// -------------------------

const Linked = ({ children, link }) => {
  return link ? (
    <Link shallow passHref href={link}>
      <Box width='fit-content'>{children}</Box>
    </Link>
  ) : (
    <Box width='fit-content'>{children}</Box>
  );
};

// -------------------------

export const SiteButton = ({
  icon,
  color,
  text,
  link = undefined,
  handleClick = undefined,
  sx,
  ...props
}) => {
  return (
    <Linked link={link}>
      <Button
        variant='link'
        startIcon={icon}
        onClick={handleClick}
        sx={{
          color: color,
          svg: { height: 24, width: 24 },
          path: {
            fill: color
          },
          ...sx
        }}
        {...props}
      >
        {text}
      </Button>
    </Linked>
  );
};
